import durag_anime from '../../../media/videos/durag_anime.MP4';
import bored_animation from '../../../media/videos/im_bored_animation.MP4';
import this_sucks_animation from '../../../media/videos/this_sucks.MP4';


let videoArray = [
    ["You Like Anime?", durag_anime],
    ["I'm Bored...", bored_animation],
    ["This Sucks...", this_sucks_animation],
]
let videoObject = {
    local: videoArray,
    external: [["Protagonist - Black Anime Kevin", "https://www.youtube.com/embed/cnc36gKqeX0"]],
}

export default videoObject;